import React, { useContext, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { BACKEND_API } from "../../constants/backendApi";

//Style
import "./FormDocsComponent.css";

//Context
import { LoadingContext } from "../../context/LoadingContext";
import { PopupContext } from "../../context/PopupContext";

const FormDocsComponent = ({ inputData, getSelectedValue }) => {
  const { setLoading, setHasComplementTxt } = useContext(LoadingContext);

  const { setShowPopup, setPopupData } = useContext(PopupContext);

  const location = useLocation();

  const history = useNavigate();

  const [, setPlate] = useState("");

  const [insuranceVal, setInsuranceVal] = useState("");

  const [doc, setDoc] = useState(null);

  const [ccType, setCcType] = useState("");

  const [docType, setDocType] = useState("");

  const [, setInputTextInfo] = useState("");

  const token = localStorage.getItem("token");

  const handleInput = (e) => {
    if (e.target.name === "cc-type") {
      console.log(e.target.value);
      getSelectedValue(e.target.value);
      setCcType(e.target.value);
    }

    if (e.target.name === "insurer-name") {
      getSelectedValue(e.target.value);
      if (
        e.target.value.includes("MUNDIAL") ||
        e.target.value.includes("SOLIDARIA") ||
        e.target.value.includes("EQUIDAD") ||
        e.target.value.includes("SURA")
      ) {
        const valueInsurerArr = e.target.value.split("+");
        const docTypeVal = `formato_${valueInsurerArr[0]}`;
        setDocType(docTypeVal);
        setInsuranceVal(valueInsurerArr[1]);
      } else {
        setInsuranceVal(e.target.value);
      }
    }

    if (e.target.name === "doc") {
      setDoc(e.target.files[0]);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    setHasComplementTxt(true);

    const formData = new FormData();

    const baseEndpoint = inputData.endpoint;

    let endpointWithParams = "";

    const idFromLS = localStorage.getItem("form_id");

    // const userIdFromLs = localStorage.getItem("userId")

    switch (location.pathname) {
      case "/admin/crear-credito/adjuntar-documento":
        if (ccType !== "extranjeria") {
          formData.append("file", doc, doc.name);
          formData.append("type_doc", ccType);
          formData.append("id", idFromLS);
          formData.append("insurance", "CC");
          // formData.append("id_intermediario", userIdFromLs)

          endpointWithParams = `${baseEndpoint}?_id=${idFromLS}&insurance=CC`;
        }
        break;

      case "/admin/crear-credito/adjuntar-poliza":
        const valueInsurerArr = insuranceVal.split("-");

        localStorage.setItem("insurance_id", valueInsurerArr[1]);

        formData.append("file", doc, doc.name);
        formData.append("type_doc", docType ? docType : "other");
        formData.append("id", idFromLS);
        formData.append("insurance", insuranceVal);
        // formData.append("id_intermediario", userIdFromLs)
        endpointWithParams = `${baseEndpoint}?_id=${idFromLS}&insurance=${insuranceVal}`;
        break;

      default:
        break;
    }

    try {
      switch (location.pathname) {
        case "/admin/crear-credito/adjuntar-documento":
        case "/admin/crear-credito/adjuntar-poliza":
          const idFromLS = localStorage.getItem("form_id");

          const emailFormData = new FormData();

          const message = `Hola, has recibido una ${
            inputData.type === "document" ? "cedula" : "poliza"
          } de un cliente desde nuestro portal. Abre este correo para revisarlo.`;

          emailFormData.append("message", message);
          emailFormData.append("sender", inputData.sender);
          emailFormData.append("subject", inputData.subject);
          emailFormData.append("document", idFromLS);

          emailFormData.append(
            inputData.type === "document" ? "cc" : "policy",
            doc
          );

          fetch(inputData.emailEndpoint, {
            method: "POST",
            body: emailFormData,
          }).then((data) => {
            if (data.status === 200) {
              if (ccType === "extranjeria") {
                const reader = new FileReader();

                reader.onload = function (event) {
                  const base64Data = event.target.result.split(",")[1];

                  const idForm = localStorage.getItem("form_id");

                  const body = {
                    id: idForm,
                    name: doc.name,
                    body: base64Data,
                  };

                  fetch(`${BACKEND_API}/api/upload-document`, {
                    method: "POST",
                    body: JSON.stringify(body),
                    headers: {
                      "content-type": "application/json",
                      Authorization: `Bearer ${token}`,
                    },
                  })
                    .then((res) => res.json())
                    .then((data) => {
                      if (data.data?.NoDoc) {
                        setDoc(null);
                        localStorage.setItem("latestStep", "attach-document");
                        history(inputData.redirect);
                      }
                    })
                    .catch((err) => {
                      console.log(err);
                    })
                    .finally(() => {
                      setLoading(false);
                    });
                };

                reader.readAsDataURL(doc);
              } else {
                fetch(endpointWithParams, {
                  method: "POST",
                  body: formData,
                }).then(() => {
                  switch (location.pathname) {
                    case "/admin/crear-credito/adjuntar-poliza":
                      const userData = JSON.parse(
                        localStorage.getItem("userData")
                      );

                      localStorage.setItem("latestStep", "attach-policy");

                      if (userData && Object.keys(userData).length !== 0) {
                        setPlate("");
                        setDoc(null);
                        setInputTextInfo("");
                        setTimeout(() => {
                          setHasComplementTxt(false);
                          setLoading(false);
                          history("/admin/crear-credito/adjuntar-anexo");
                        }, 15000);
                      } else {
                        setPlate("");
                        setDoc(null);
                        setInputTextInfo("");
                        setTimeout(() => {
                          setHasComplementTxt(false);
                          setLoading(false);
                          history(inputData.redirect);
                        }, 15000);
                      }

                      break;
                    case "/admin/crear-credito/adjuntar-documento":
                      localStorage.setItem("latestStep", "attach-document");

                      setPlate("");
                      setDoc(null);
                      setInputTextInfo("");
                      setTimeout(() => {
                        setHasComplementTxt(false);
                        setLoading(false);
                        history(inputData.redirect);
                      }, 15000);

                      break;
                    default:
                      break;
                  }
                });
              }
            }
          });
          break;

        case "/admin/crear-credito/adjuntar-anexo":
          const reader = new FileReader();

          reader.onload = function (event) {
            const base64Data = event.target.result.split(",")[1];

            const idForm = localStorage.getItem("form_id");

            const body = {
              id: idForm,
              name: doc.name,
              body: base64Data,
            };

            fetch(`${BACKEND_API}/api/${inputData.endpoint}`, {
              method: "POST",
              body: JSON.stringify(body),
              headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            })
              .then((res) => res.json())
              .then((data) => {
                if (data.data?.NoDoc) {
                  setDoc(null);
                  setShowPopup(true);
                  setPopupData({
                    title: "Enviado Exitosamente",
                    info: `El archivo ha sido enviado exitosamente.`,
                    type: "correct",
                  });
                }
              })
              .catch((err) => {
                console.log(err);
              })
              .finally(() => {
                setLoading(false);
              });
          };

          reader.readAsDataURL(doc);

          break;

        default:
          break;
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleNext = () => {
    const processType = localStorage.getItem("processType");

    localStorage.setItem("latestStep", "attach-appendix");

    switch (processType) {
      case "new-credit":
        setHasComplementTxt(false);
        history("/admin/crear-credito/formulario");
        break;

      case "renovation":
        setHasComplementTxt(false);
        history("/admin/crear-credito/verificar-datos");
        break;

      default:
        break;
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      encType="multipart/form-data"
      className="form-container-docs"
    >
      <div className="txt-input-container">
        {inputData.headerFields &&
          inputData.headerFields.map((field, i) => {
            return (
              <label
                className={`select-label ${field.isHidden ? "hide-label" : ""}`}
                key={i}
              >
                {field.title}
                <select
                  onChange={(e) => handleInput(e)}
                  name={field.name}
                  required={field.isRequired}
                >
                  <option value="">--Ninguno--</option>
                  {field.options.map((opt, i) => {
                    return (
                      <option
                        key={i}
                        value={opt.value}
                        selected={opt.isSelected}
                      >
                        {opt.title}
                      </option>
                    );
                  })}
                </select>
              </label>
            );
          })}
      </div>
      <div className="inputs-file-container">
        {inputData.inputs.map((input, i) => {
          return (
            <label key={i}>
              {input.labelTitle}
              <input
                type="file"
                onChange={(e) => handleInput(e)}
                accept={input.accept}
                name={input.name}
                required={input.isRequired}
                id={input.id}
              />
              <p className="input-subtitle">{input.labelSubtitle}</p>
            </label>
          );
        })}
      </div>
      <div className="form-doc-btn-container">
        <button title="Enviar" className="form-doc-btn" type="submit">
          Enviar
        </button>
        {location.pathname === "/admin/crear-credito/adjuntar-anexo" && (
          <button
            title="Siguiente"
            className="form-doc-btn"
            type="button"
            onClick={handleNext}
          >
            Siguiente
          </button>
        )}
      </div>
    </form>
  );
};

export default FormDocsComponent;
